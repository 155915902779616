import {
    participantLabels,
    PARTICIPANT_UI_PATH,
    PREVIEW,
    REGISTRATION_FORM,
    EVENT_URL,
    eventBrandingWebsite,
    messageModule,
    EDIT_PROFILE,
    EVENT_REDIRECTION_URL,
    moderationWithMagicLink,
} from 'utils/constants';

const routesPaths = {
    HOME: '/',
    MODERATION: '/moderation',
    MODERATOR_DASHBOARD: '/moderate/:sessionId/:token?',
    PARTICIPANTS: `/:eventId/${participantLabels.PARTICIPANTS.toLowerCase()}`,
    DASHBOARD: '/:eventId/dashboard',
    DETAILS: '/:eventId/event-details',
    PARTICIPANTS_DATA_SETTINGS: '/:eventId/setup/custom-fields',
    SESSIONS_DATA_SETTINGS: '/:eventId/setup/sessions',
    REPORTS_DASHBOARD: '/:eventId/setup/reports',
    REPORT_DETAIL: '/:eventId/setup/reports/:reportId',
    DATA_IMPORT:
        '/:eventId/setup/data-import/:tableName?/:sequenceNumber?/:importId?',
    THIRD_PARTY_IMPORT: '/:eventId/setup/third-party-import',
    THIRD_PARTY_IMPORT_DETAIL:
        '/:eventId/setup/third-party-import/:integrationId',
    MATERIALS: '/:eventId/materials',
    INTERACTIVE_MAPS: '/:eventId/interactive-maps',
    DIRECTORIES: '/:eventId/directories',
    ENGAGEMENT_TOOLS: '/:eventId/engagement-tools',
    GAMIFICATIONS: '/:eventId/setup/gamifications',
    FRONT_OF_ROOM_LEADER_BOARD: '/:eventId/leader-board/:gamificationId',
    MEETINGS: '/:eventId/meetings',
    COMMUNICATIONS: `/:eventId/${messageModule.COMMUNICATIONS.toLowerCase()}`,
    EMAILS: `/:eventId/${messageModule.EMAILS.toLowerCase()}`,
    APP_DESIGNER: '/:eventId/setup/app-designer/:pageId?',
    WEB_DESIGNER: '/:eventId/setup/web-designer',
    DATA_IMPORT_DETAIL:
        '/:eventId/setup/data-import/:tableName/:sequenceNumber/:importId',
    LOGIN_CALLBACK: '/login/callback',
    LOGIN: '/login',
    LOGOUT: '/logout',
    ADMIN_LOGIN: '/admin/login',
    EVENT_LOGIN: '/event/login',
    EVENT_WEBSITE: `${eventBrandingWebsite}:eventId/:pageId/:type?`,
    REGISTRATION: '/registration',
    VERIFY_EMAIL: '/verify-email/:token',
    LINK_REDIRECTION: '/link-redirection/:eventId/:pageId',
    PARTICIPANT_PROFILE: `/:eventId/${participantLabels.PARTICIPANTS.toLowerCase()}/:participantId`,
    LOCATION: '/:eventId/setup/locations',
    CUSTOM_QR_CODES: '/:eventId/setup/custom-qr-codes',
    LABELS: '/:eventId/setup/labels',
    REGISTRATIONS: '/:eventId/setup/registrations',
    REGISTRATION_FORM: `/event/:eventId/${REGISTRATION_FORM}/:pageId`,
    PREVIEW_REGISTRATION_FORM: `/${PREVIEW}/event/:eventId/${REGISTRATION_FORM}/:pageId`,
    SESSIONS: '/:eventId/agenda',
    RESET_PASSWORD: '/reset-password',
    FORGOT_PASSWORD: '/forgot-password',
    CREATE_NEW_PASSWORD: '/create-new-password',
    PREVIEW_EVENT_PAGE: `/${PREVIEW}${PARTICIPANT_UI_PATH}`,
    PREVIEW_ACTIVITY_FEED_PAGE: `/${PREVIEW}${PARTICIPANT_UI_PATH}/activity-feed`,
    PREVIEW_PARTICIPANT_EDIT_DETIAL_PAGE: `/${PREVIEW}/event/:eventId/:pageId/${EDIT_PROFILE}`,
    PREVIEW_EXTERNAL_QR_CODE_PAGE: `/${PREVIEW}${PARTICIPANT_UI_PATH}/external-qr-code`,
    PREVIEW_WIDGET_PAGE: `/${PREVIEW}/event/:eventId/:pageId/:widgetId`,
    PREVIEW_SUB_DETAIL_PAGE: `/${PREVIEW}/event/:eventId/:pageId/:typeOfScreen/:widgetId/:itemId?`,
    PREVIEW_SUB_DETAIL_DIRECTORY_PAGE: `/${PREVIEW}/event/:eventId/:pageId/widgets-module/:widgetId/:itemId?/:directoryId?`,
    SUPER_ADMIN: {
        REPORTS: '/super-admin/reports',
        APP_VERSIONS: '/super-admin/app-versions',
        REPORT_DETAIL: '/super-admin/reports/:reportId',
        SUPER_ADMIN: '/super-admin',
        ORGANIZATIONS: '/super-admin/organizations',
        EVENT_HUBS: '/super-admin/event-hubs',
        EVENTS: '/super-admin/events',
        ADMIN_USERS: '/super-admin/admin-users',
    },
    ADMIN_USERS: {
        ADMIN_USERS: '/administration/admin-users',
    },
    PARTICIPANT_USER: {
        EVENT_DETIAL_PAGE: PARTICIPANT_UI_PATH,
        ACTIVITY_FEED_PAGE: `${PARTICIPANT_UI_PATH}/activity-feed`,
        PARTICIPANT_EDIT_DETIAL_PAGE: `/event/:eventId/:pageId/${EDIT_PROFILE}`,
        EXTERNAL_QR_CODE_PAGE: `${PARTICIPANT_UI_PATH}/external-qr-code`,
        EVENT_URL_PAGE: `/${EVENT_URL}/:eventId`,
        REDIRECT_URL_PAGE: `/${EVENT_REDIRECTION_URL}`,
        NO_EVENT_ACCESS: `/no-event-access`,
        WIDGET_DETAIL_PAGE: '/event/:eventId/:pageId/:widgetId',
        SUB_DETAIL_PAGE:
            '/event/:eventId/:pageId/:typeOfScreen/:widgetId/:itemId?',
        SUB_DETAIL_DIRECTORY_PAGE:
            '/event/:eventId/:pageId/widgets-module/:widgetId/:itemId?/:directoryId?',
    },
    AGGREGATED_SURVEY_POLL_RESULT:
        '/view-aggregated-result/:eventId/:engagementToolId',
    SURVEY_POLL_RESULT: '/view-result/:eventId/:engagementToolId/:instanceId',
    MODERATE_QA: '/qa/:engagementToolId/:instanceId',
    MODERATE_QA_SPEAKER_VIEW: '/qa-speaker-view/:engagementToolId/:instanceId',
    MODERATE_QA_WITH_MAGIC_LINK: `/${moderationWithMagicLink}/qa/:engagementToolId/:instanceId`,
    MODERATE_QA_SPEAKER_VIEW_WITH_MAGIC_LINK: `/${moderationWithMagicLink}/qa-speaker-view/:engagementToolId/:instanceId`,
};

export default routesPaths;
